<template>
    <vue-table-card :showSearchBox="false" class="m-3 c-table" title="Semen Storage" :fields="fields"
                    url="https://vuetable.ratiw.net/api/users" :per-page="5"
                    ref="table">
        <template #buttons>
            <btn text="Add" @click="showAddModal" icon="fa fa-plus" size="sm"></btn>
        </template>
        <template #footer>
            <modal ref="addModal" :no-close-on-backdrop="true" title="Add Semen Storage">
                <s-form>
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="fl-x-cc">
                                <validated-input type="number" label="No."></validated-input>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="fl-x-cc">
                                <validated-input label="Name"></validated-input>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div class="fl-x-cc h-100">
                                <btn size="sm" text="Add" class="mt-2 px-5" type="submit"></btn>
                            </div>
                        </div>
                    </div>
                </s-form>
            </modal>
            <modal ref="editModal" :no-close-on-backdrop="true" title="Edit Semen Storage">
                <s-form>
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="fl-x-cc">
                                <validated-input type="number" label="No."></validated-input>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="fl-x-cc">
                                <validated-input label="Name"></validated-input>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div class="fl-x-cc h-100">
                                <btn size="sm" text="Update" class="mt-2 px-5" type="submit"></btn>
                            </div>
                        </div>
                    </div>
                </s-form>
            </modal>
            <delete-modal enter-animation="animated fadeIn" exit-animation="animated fadeOut" ref="deleteModal"
                          :url="deleteUrl" :params="{id:itemID}" @response="deleteComplete">
                <p> Are you sure ?</p>
                <template #loading>
                    <div class="fl-x">
                        <loading-animation/>
                        <b class="ml-3">Please Wait...</b>
                    </div>
                </template>
            </delete-modal>
        </template>
        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <delete-btn color="primary" text="Edit" icon="" size="sm" @click="setEdit(rowData)"/>
                <delete-btn icon="" size="sm" @click="setDelete(rowData)"/>
            </div>
        </template>
    </vue-table-card>
</template>

<script>

export default {
    name: 'SemenStorageListing',
    data () {
        return {
            model: {
                name: ''
            },
            rules: {
                name: {
                    required: true
                }
            },
            fields: [
                {
                    name: 'nickname',
                    title: 'User Name'
                },
                {
                    name: 'name',
                    title: 'Name'
                },
                {
                    name: 'email',
                    title: 'Branch'
                },
                {
                    name: 'gender',
                    title: 'Admin User'
                },
                {
                    name: '__slot:actions',
                    title: 'Actions'
                }
            ]
        };
    },
    methods: {
        openAddModal () {
            this.$refs.addModal.show();
        },
        openEditModal () {
            this.$refs.editModal.show();
        },
        showAddModal () {
            this.model = {};
            this.$refs.addModal.show();
        },
        setEdit (item) {
            this.model = { ...item };
            this.$refs.editModal.show();
        },
        setDelete (item) {
            // this.deletingItem.id = item.id;
            console.log(item);
            this.$refs.deleteModal.show();
        }
    }
};
</script>

<style scoped>

</style>
